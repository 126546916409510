import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class ImageComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false
        };
    }

    render() {

        return (
            <div>
                <button type="button" onClick={() => this.setState({ isOpen: true })}>
                    <img src={'../img/art/' + this.props.fileName}  alt={'Art by ' + this.props.artist} height="100%" width="100%"/>
                </button>

                {this.state.isOpen && (
                    <Lightbox
                        mainSrc={'../img/art/' + this.props.fileName}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        imageCaption={<div><a href={this.props.url}>Artist: {this.props.artist}</a></div>}
                    />
                )}
            </div>
        );
    }
}

