import '../css/App.css';
import pfp from '../img/raemyne-pfp.png'
import frog from '../img/frogge.png'

function Home() {
    return (
            <header className="App-header">
                <div className="content">
                    <div className="pfp">
                        <img src={pfp} alt=""/>
                    </div>

                    <div className="description">
                        <img src={frog} alt="" className="frog-border"/>
                        <p>🐸 Variety streamer with a Dory brain, a love of frogs and a general mess, but sometimes a funny one 🐸 Welcome! I'm just a completely **normal human** 👀 here to suffer and make you suffer with me 💚Not a bottom ™</p>
                    </div>

                    <a href="https://twitch.tv/raemyne" className="btn">
                        <div>Twitch</div>
                    </a>
                    <a href="https://twitter.com/raemyne" className="btn">
                        <div>Twitter</div>
                    </a>
                    <a href="https://www.tiktok.com/@raemynevt" className="btn">
                        <div>TikTok</div>
                    </a>
                    <a href="https://www.youtube.com/c/Raemyne" className="btn">
                        <div>YouTube</div>
                    </a>
                    <a href="https://discord.gg/dMBDXJ8Bkp" className="btn">
                        <div>Discord</div>
                    </a>
                    <a href="https://www.redbubble.com/people/raemyne/shop?asc=u" className="btn">
                        <div>Merch</div>
                    </a>
                    <a href="https://throne.me/u/raemyne" className="btn">
                        <div>Throne</div>
                    </a>
                    <a href="https://streamelements.com/raemyne/tip" className="btn">
                        <div>Donate</div>
                    </a>
                </div>
            </header>
    );
}

export default Home;
