import algoliasearch from 'algoliasearch/lite';
import React, {Component} from 'react';
import {InstantSearch, SearchBox, Configure, Highlight} from 'react-instantsearch-hooks-web';
import InfiniteHitsArt from '../components/InfiniteHitsArt';
import '../css/App.css';
import '../css/Algolia.css';
import '../css/algolia-min.css';
import ImageComponent from "../components/ImageComponent";

const searchClient = algoliasearch(
    'EGL5028U25',
    '084f2b05bdf3df782a8562fc96e91fde'
);

class Art extends Component {
    render() {
        return (
             <div>
                 <div style={{fontSize: '2em', fontWeight: 'bold', paddingTop: '5px'}}>Raemyne Art Gallery</div>
                <div className="ais-InstantSearch">

                    <InstantSearch indexName="raemyne-art" searchClient={searchClient}>
                        <Configure hitsPerPage={4}/>
                        <SearchBox/>
                        <InfiniteHitsArt hitComponent={Hit}/>
                    </InstantSearch>
                </div>
             </div>
        );
    }
}

function Hit({hit}) {
    return (
        <div className="image-container">
            <div>
                <ImageComponent artist={hit.artist} fileName={hit.ID} url={hit.url} />
            </div>
            <div className="hit-description">
                <a style={{color: 'black'}} href={hit.url}>Artist: <Highlight hit={hit} attribute={"artist"} /></a>
            </div>
        </div>
    );
}

export default Art;
