import algoliasearch from 'algoliasearch/lite';
import React, {Component} from 'react';
import {InstantSearch, SearchBox, Configure, Highlight} from 'react-instantsearch-hooks-web';
import InfiniteHits from '../components/InfiniteHits';
import '../css/App.css';
import '../css/Algolia.css';
import '../css/algolia-min.css';

const searchClient = algoliasearch(
    'EGL5028U25',
    '084f2b05bdf3df782a8562fc96e91fde'
);

class Clips extends Component {
    render() {
        return (
             <div>
                 <div style={{fontSize: '2em', fontWeight: 'bold', paddingTop: '5px'}}>Raemyne Top Twitch Clips</div>
                <div className="ais-InstantSearch">

                    <InstantSearch indexName="raemyne-clips" searchClient={searchClient}>
                        <Configure hitsPerPage={4}/>
                        <SearchBox/>
                        <InfiniteHits hitComponent={Hit}/>
                    </InstantSearch>
                </div>
             </div>
        );
    }
}

function Hit({hit}) {
    return (
        <div>
            <iframe
                src={`https://clips.twitch.tv/embed?clip=${hit.ID}&parent=${window.location.hostname}`}
                frameBorder="0" allowFullScreen="true" scrolling="no" height="378" width="100%"></iframe>
            <div className="hit-name">
                <Highlight attribute="Title" hit={hit}/>
            </div>
            <div className="hit-description">
                Clipped by <Highlight attribute="Clipper" hit={hit}/>
            </div>
            <div className="hit-description">{hit.Game}</div>
        </div>
    );
}

export default Clips;
